.item-scroll-image {
    width: 40px;
    flex-grow: 1;
    margin-left: 10px;
}

.item-scroll {
    display: flex;
    height: 50px;
    align-items: center;
    width: 300px;
    /* background-color: #fff; */
    padding: 0 10px;
    position: absolute;
    transition: left 0.45s;
    cursor: pointer;
    left: 20px;
}

.item-scroll-text {
    width: 250px;
    border-right: 1px solid #fff;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}

.item-scroll:hover {
    left: 260px;
}
.item-scroll:hover .item-scroll-image {
    fill: #fc8b06;
    stroke: #fc8b06;
}