.contact_us {
    padding: 50px 0;
}

.contact_grid {
    border-radius: 20px;
    padding: 40px;
    background-color: #F2F6F7;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    column-gap: 50px;
}

.title-text {
    color: #343a40!important;
    text-align: center!important;
}

.small-text {
    font-size: 16px!important;
    color: #343a40!important;
}

.motoboard-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 350px;
    padding: 40px;
}

.responsive-video {
    width: 100%;
    height: auto;
    max-height: 100%;
}

.catalog {

}

.catalog-cards {
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    gap: 50px;
}



@media screen and (max-width: 820px) {
    .contact_us {
        padding: 20px 0;
    }

    .contact_grid {
        flex-direction: column;
        row-gap: 20px;
    }

    .motoboard-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 0px;
    }

    .motoboard-block img {
        display: none;
    }

    .catalog-cards {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        gap: 50px;
    }
}

@media screen and (max-width: 500px) {
    .contact_us {
        padding: 20px 0;
    }

    .contact_grid {
        flex-direction: column;
        row-gap: 20px;
    }

    .motoboard-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 0px;
    }

    .motoboard-block img {
        display: none;
    }

    .catalog-cards {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 50px;
    }
}