.working_time {
    /*position: absolute;*/
    /*right: 17%;*/
    /*top: 20%;*/
    width: 100%;
    /*max-width: 350px;*/
    padding: 20px;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0 0 15px 4px rgba(255,255,255,0.6);
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #fff;
    margin-left: 150px;
}

.working-time-top-wrapper {
    display: flex;
    column-gap: 40px;
}

.working-time-top {
    display: block;
    position: relative;
}

.working-time-top:nth-child(2)::before, .working-time-top:nth-child(3)::before {
    content: '';
    display: block;
    position: absolute;
    font-size: 42px;
    width: 2px;
    height: 100px;
    font-weight: 600;
    top: 0;
    left: -21px;
    background-color: #fc8b06;
}

.working_time.mobile, .working_time_card.mobile {
    /*z-index: 1;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    width: 90%;
    margin-left: 0;
}

.phone_working {
    color: #fc8b06;
}

.working_time_card {
    /*position: absolute;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    /*top: 20%;*/
    max-width: 450px;
    padding: 20px;
    z-index: 9999;
    border-radius: 10px;
    box-shadow: 0 0 15px 4px rgba(255,255,255,0.6);
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #fff;
}

.working_time_logo img {
    width: 50%;
}

.working_time_title {
    font-size: 42px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
}

@media screen and (max-width: 500px) {
    .working_time_title {
        font-size: 30px !important;
    }

    .home-page .working_time_title {
        font-size: 26px !important;
    }

    .home-page .working_time_logo img {
        width: 30%;
    }

    .working-time-top-wrapper {
        flex-direction: column;
        row-gap: 30px;
    }

    .working-time-top:nth-child(2)::before, .working-time-top:nth-child(3)::before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #fc8b06;
        left: 0;
        top: -20px;
    }
}

@media screen and (max-width: 385px) {
    .working_time_title {
        font-size: 30px !important;
    }

    .home-page .working_time_title {
        font-size: 24px !important;
    }

    .home-page .working_time_logo img {
        width: 25%;
    }

    /* .working-time-top-wrapper {
        flex-direction: column;
        row-gap: 30px;
    }

    .working-time-top:nth-child(2)::before, .working-time-top:nth-child(3)::before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #fc8b06;
        left: 0;
        top: -20px;
    } */
}


.working_time_text {
    border: 2px solid #fc8b06;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 20px;
}

.source {
    font-size: 30px;
    font-weight: 500;

}

.source_time {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #fc8b06;
}

.source_brake {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
}

.source_phone {
    font-size: 20px;
    font-weight: 500;
    color: #fc8b06;
}