.first-screen-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
}

.react-parallax .first-screen-image {
    height: 1000px !important;
}
.react-parallax::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, .75);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

@media screen and (max-width: 500px) {
    .react-parallax .first-screen-image {
        height: auto !important;
    }
}