.contact_us {
    padding: 50px 0;
}

.contact_grid {
    border-radius: 20px;
    padding: 40px;
    background-color: #F2F6F7;
    display: flex;
    align-items: center;
    column-gap: 50px;
}

.contact_grid_tires {
    border-radius: 20px;
    padding: 40px;
    background-color: #F2F6F7;
    /* display: flex; */
    align-items: center;
    column-gap: 50px;
}

.contact_grid_text p {
    line-height: 24px;
    color: #172126;
    margin-top: 16px;
}

.contact_grid_text h2 {
    font-size: 48px;
    line-height: 48px;
    color: #172126;
}

.tires-h2 {
    text-align: center;
}

.contact_grid_icon img {
    width: 60px;
}

.contact_grid_icon {
    display: flex;
    gap: 15px;
}

.contact_phone img {
    width: 30px;
}

.contact_phone {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-top: 15px;
}

.alternative a{
    color: #ffffff !important;
}
.alternative a:hover{
    color: #fc8b06 !important;
}

.contact_phone a {
    font-weight: 500;
    color: #000;
    transition: color 350ms;
}

.contact_phone a:hover {
    color: #fc8b06;
}

.mobile .contact_grid {
    flex-direction: column;
    row-gap: 20px;
}

.mobile .contact_us {
    padding: 20px 0;
}

@media screen and (max-width: 820px) {
    .contact_grid_text {
        text-align: center;
    }
}