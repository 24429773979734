.section-title {
    font-size: 60px;
    text-align: center;
    margin-bottom: 30px;
}

.section {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    grid-row-gap: 30px;
    padding: 30px 0;
    line-height: 26px;
    height: 100vh;
    justify-content: center;
}

.section-grid {
    margin-top: 50px;
}

.section-rellax {
    color: #fff;
}
.section:nth-child(odd) {
    /* background-color: #d1d1d1; */
}

.section-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
}

.section-list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 20px;
}

.section-item-image {
    width: 100%;
    border-radius: 15px;
}

.mobile .section-item {
    grid-template-columns: 1fr;
}

.item-right-h {
    font-weight: bold;
    text-align: center;
}

.item-right-list {
    list-style: square;
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: 'Nunito', sans-serif;
}

.item-right-list-tires {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin: 15px auto;
    list-style: square;
}
.mobile .section-item-image {
    display: none;
}

.section-rellax .section-item-right {
    border-radius: 10px;
    box-shadow: 0px 0px 15px 4px rgb(255 255 255 / 60%);
    padding: 30px;
}

.section-rellax .section-item-left {
    display: flex;
    justify-content: center;
    line-height: 80px;
}

.section-rellax .section-title {
    font-size: 60px;
    text-shadow: 4px 4px 2px rgb(150 150 150);
}

.span-item-right {
    color: #fc8b06;
}

.color-orange {
    color: #fc8b06;
}

.mb-10 {
    margin-bottom: 10px;
}

.section-item-left,
.section-item-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-item-left h2 {
    font-size: 60px;
    text-align: center;
    text-shadow: 4px 4px 2px rgb(150 150 150);
    text-transform: uppercase;
}

.conditioner-text {
    font-size: 14px;
}

.mobile .section-item-left h2 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 40px;
}

.mobile .map-tire {
    margin-bottom: 30px;
}

.mobile .map-wash {
    margin-top: 30px;
}

.section.mobile {
    height: auto;
}

.mobile .section-title {
    line-height: 36px;
    margin-bottom: 0;
    font-size: 30px;
}

.mobile .section-grid {
    margin-top: 20px;
}

@media screen and (max-width: 1600px) {
    .section {
        height: auto;
    }
    .form-wrapper {
        margin-top: 30px;
    }
}

@media screen and (max-width: 820px) {
    .item-right-list-tires {
        width: 88%;
        display: flex;
        flex-direction: column;
        margin: 15px auto;
        list-style: square;
    }
}

@media screen and (max-width: 500px) {
    .item-right-list-tires {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 15px auto;
        list-style: square;
    }
}
