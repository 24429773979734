.card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    width: 280px;
    height: 480px;
    background: linear-gradient(0deg, rgba(91, 93, 98, 0.6811099439775911) 50%, rgba(252, 139, 6, 0.6755077030812324) 85%);
    border-radius: 15px;
    padding: 15px;
    position: relative;
    overflow: hidden;
}

.card-title {
    font-size: 18px;
    color: #fff;
    text-align: center;
}

.card-subtitle {
    font-size: 16px;
    color: #fff;
    text-align: center;
}

.card-phone {
    padding: 8px;
    background-color: #fc8b06;
    color: #fff;
    border-radius: 6px;
    text-align: center;
    transition: .4s;
    cursor: pointer;
}

.card-phone p {
    padding: 0;
    margin: 0;
}

/*.card-phone:hover {*/
/*    color: #fa8e10;*/
/*    background-color: transparent;*/
/*}*/

.card img {
    width: auto;
    height: auto;
    transition: .4s;
}

.card img:hover {
    scale: 1.12;
}

.technical-card {
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    transition: .4s;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    overflow-y: auto;
}

.technical-card-header {
    position: sticky;
    background-color: #000000;
    left: 0;
    right: 0;
    top: 0;
}

.technical-card-body {

}

.technical-card .cancel-icon {
    position: absolute;
    top: 4px;
    right: 10px;
    cursor: pointer;
    font-size: 30px;
    fill: #fc8b06;
}

.technical-card-list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 8px;
}

.technical-card-item {
    color: #ffffff;
}

.technical-card-item h6 {
    font-size: 14px;
    color: #ffa53f;
}

.technical-card-item span {
    font-size: 12px;
}

.card-color {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    column-gap: 8px;
    padding: 8px 8px 16px 8px;
    justify-content: center;
}

.card-color-item {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.color-red {
    background-color: #9c0000;
}

.color-orange {
    background-color: #fc8907;
}

.color-blue {
    background-color: blue;
}

.color-green {
    background-color: rgb(2, 151, 2);
}

.color-white {
    background-color: rgb(243, 243, 247);
}

.color-black {
    background-color: rgb(12, 12, 12);
}