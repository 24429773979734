.about {
    padding: 50px 0;
}

.about_best_logo img {
    width: 350px;
}

.about_best {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 350px;
}

.about_best_text h4 {
    font-weight: 800;
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 20px;
}

.about_best_text p {
    font-size: 18px;
}

.about_company {
    text-align: center;
    line-height: 25px;
    margin-bottom: 80px;
    margin-top: 50px;
}

.about_company h2 {
    margin-bottom: 20px;
    font-size: 30px;
}

.about_company p {
    font-size: 18px;
}

.about_company h4 {
    font-size: 20px;
}

.contact_phone {
    justify-content: center;
}

.mobile .about_best_logo {
    display: none;
}

.mobile .about_best_text {
    text-align: center;
}

.mobile .about_company {
    margin-bottom: 20px;
}