@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700;800;900&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700;800;900&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Slab:wght@100;200;300;400;500;600;800;900&family=Roboto:ital,wght@1,100;1,300;1,500;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

a {
    text-decoration: none;
    color: #ffffff;
}

button {
    border: none;
}

ul {
    list-style: none;
}

.wrapper {
    width: 100%;
    max-width: 1360px;
    padding: 0 10px;
    box-sizing: border-box;
    margin: 0 auto;
}

.App {
    display: grid;
    grid-template-rows: 70px 1fr 70px;
    min-height: 100vh;
}

.layout {
    position: relative;
}

.block_wrap {
    border-radius: 20px;
    padding: 40px;
    background-color: #F2F6F7;
}

.mobile .block_wrap {
    padding: 10px;
    grid-template-columns: 1fr;
}

.mobile .item-right-list {
    padding-left: 20px;
}

.mobile .wrapper {
    padding: 0 10px;
}

.margin-right {
    margin-right: 8px;
}

.margin-right-2 {
    margin-right: 16px;
}

.margin-right-3 {
    margin-right: 24px;
}

.overflow-hidden {
    overflow: hidden;
}

.mt-1 {
    margin-top: 4px;
}
.mt-2 {
    margin-top: 8px;
}
.mt-3 {
    margin-top: 12px;
}
.mt-4 {
    margin-top: 16px;
}
.mt-5 {
    margin-top: 20px;
}

.pt-1 {
    padding-top: 4px;
}
.pt-2 {
    padding-top: 8px;
}
.pt-3 {
    padding-top: 12px;
}
.pt-4 {
    padding-top: 16px;
}
.pt-5 {
    padding-top: 20px;
}

.size-36 {
    font-size: 36px !important;
}

.display-block {
    display: block;
}

.display-none {
    display: none !important;
}
